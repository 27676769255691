/* body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
} */
.mainContainer {
  /* background: linear-gradient(90deg, #06d897, #ffff); */
  background: linear-gradient(90deg, #5d5c95, #ffff);
  width: 100%;
  min-height: 100vh;
  max-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}
.tabContainer {
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 80%;
  /* max-height: fit-content; */
  border-radius: 10px;
  background-color: #d4d4f9;
  box-shadow: 0px 2px 5px 0px rgba(67, 67, 67, 0.5);
  padding: 20px;
  margin: 50px 5px 50px 5px;
  /* overflow-y: scroll; */
}
.labelHeader {
  align-items: flex-start;
  text-align: left;
  justify-content: left;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: xx-large;
  color: #5d5c95;
  font-weight: 600;
}
.tabButtons {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: left;
  margin-top: 20px;
}
.tabButtons button {
  background-color: white;
  margin: 15px;
  border: none;
  cursor: pointer;
  color: #5d5c95;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: medium;
}

.tabButtons button.active {
  border-bottom: 3px solid #5d5c95;
}
.personalDetailsContainer {
  margin-top: 10px;
  padding: 20px 10px 20px 10px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* row-gap: 30px;
  column-gap: 20px;
  grid-template-columns: repeat(2, 1fr); */
}
.mandatoryLabel {
  display: flex;
  text-align: left;
  justify-content: left;
  align-items: left;
}
.formGrid {
  display: grid;
  column-gap: 20px;
  row-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}
@media screen and (max-width: 680px) {
  .formGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.fieldStyles {
  display: flex;
  border: none;
  padding: 15px;
  border-radius: 5px;
  justify-content: left;
  width: 90%;
}
.genderRadioGroup {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: left;
  align-items: center;
  border: none;
  padding: 15px;
  border-radius: 5px;
  width: 90%;
}
.genderLabel {
  font-size: small;
}
.radioLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0px 5px;
  font-size: small;
  color: rgb(81, 81, 81);
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.error {
  font-size: x-small;
  color: red;
  text-align: left;
  margin: 3px 7px 0px 7px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 10px 25px 10px 25px;
  border-radius: 200px;
  background-color: #5d5c95;
  color: white;
  font-weight: 600;
  cursor: pointer;
  font-size: medium;
  height: fit-content;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.nextButton:disabled {
  background-color: rgb(234, 234, 234);
  color: rgb(159, 159, 159);
  cursor: auto;
}
.healthDetailsContainer {
  margin-top: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
}
.questionsStyleBox {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  margin: 10px 0px 10px 0px;
}
.doneButton {
  border: none;
  padding: 10px 25px 10px 25px;
  border-radius: 200px;
  background-color: #27dba2;
  color: white;
  cursor: pointer;
  width: fit-content;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
}

.modalContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.closeButtonContainer {
  justify-content: right;
  align-items: right;
  text-align: right;
  /* margin-bottom: px; */
}
.otpField {
  margin: 0px 10px 10px 10px;
  background-color: #ededed;
  border: none;
  border-radius: 5px;
  height: 30px;
}
.questionLabel {
  font-size: small;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: rgb(81, 81, 81);
  font-weight: 600;
}
.questionField {
  margin-bottom: 20px;
}
.fieldStylesQuestions {
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 90%;
  margin-top: 5px;
}
.dividerStyles {
  margin: -10px 0px 12px 0px !important;
}
.verifyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 5px 25px 5px 25px;
  border-radius: 200px;
  background-color: #46c6a0;
  box-shadow: 0px 1px 4px 0px #4b4b4b;
  color: white;
  cursor: pointer;
  width: fit-content;
  font-size: small;
  margin-top: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
.verifyButton:disabled {
  background-color: rgb(234, 234, 234);
  color: rgb(159, 159, 159);
  cursor: auto;
}
.verifyBtnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.verifyOTPButton {
  border: none;
  padding: 10px 25px 10px 25px;
  border-radius: 200px;
  background-color: #5d5c95;
  color: white;
  cursor: pointer;
  width: fit-content;
  margin: 0px 10px 10px 10px;
}
.verifyOTPButton:disabled {
  background-color: rgb(234, 234, 234);
  color: rgb(159, 159, 159);
  cursor: auto;
}
.verifiedLabel {
  color: #25c08f;
  display: flex;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: small;
  /* background-color: #f1f1f1; */
  border-radius: 20px;
  padding: 3px 15px 2px 15px;
  width: fit-content;
  background-color: rgb(240, 240, 240);
  margin: 5px;
  border: 2px solid #25c08f;
}
.modalContent2 {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.tickIcon {
  height: 50px;
  width: 50px;
  color: rgb(65, 194, 65);
}
.formLabel {
  display: flex;
  justify-content: left;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4b4b4b;
  margin: 3px;
}
/* .otpImage {
  flex-direction: column;
  position: relative;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/optImage.avif");
  border-radius: 10px 0 0 10px;
  margin: 10px 30px 30px;
}
.otpImageCover img {
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/optImage.avif");
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
} */
.optImageContainer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.otpImage {
  max-width: 100%;
  max-height: 100%;
  display: block; /* Ensures that it behaves like a block-level element */
}

.otpVerificationLabel {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.labelWording {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: medium;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}
.labelNumber {
  font-weight: 500;
  font-size: medium;
}
