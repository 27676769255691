/* body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
} */
.mainContainer {
  /* background: linear-gradient(90deg, #06d897, #ffff); */
  background: linear-gradient(90deg, #5d5c95, #ffff);
  width: 100%;
  min-height: 100vh;
  max-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  overflow-y: auto;
}
.tabContainer {
  display: flex;
  flex-direction: column;
  width: 85%;
  height: 80%;
  /* max-height: fit-content; */
  border-radius: 10px;
  background-color: #d4d4f9;
  box-shadow: 0px 2px 5px 0px rgba(67, 67, 67, 0.5);
  padding: 20px;
  margin: 50px 5px 50px 5px;
  /* overflow-y: scroll; */
}
.labelHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  justify-content: space-between;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: xx-large;
  color: #5d5c95;
  font-weight: 600;
}
.tabButtons {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: left;
  margin-top: 20px;
}
.tabButtons button {
  background-color: white;
  margin: 15px;
  border: none;
  cursor: pointer;
  color: #5d5c95;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: medium;
}

.tabButtons button.active {
  border-bottom: 3px solid #5d5c95;
}
.personalDetailsContainer {
  margin-top: 10px;
  padding: 20px 10px 20px 10px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* row-gap: 30px;
    column-gap: 20px;
    grid-template-columns: repeat(2, 1fr); */
}
.mandatoryLabel {
  display: flex;
  text-align: left;
  justify-content: left;
  align-items: left;
}
.formGrid {
  display: grid;
  column-gap: 20px;
  row-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}
@media screen and (max-width: 680px) {
  .formGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.fieldStyles {
  display: flex;
  border: none;
  padding: 15px;
  border-radius: 5px;
  justify-content: left;
  width: 90%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}
.genderRadioGroup {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: left;
  align-items: center;
  border: none;
  padding: 15px;
  border-radius: 5px;
  width: 90%;
}
.genderLabel {
  font-size: small;
}
.radioLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0px 5px;
  font-size: small;
  color: rgb(81, 81, 81);
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.error {
  font-size: 0.75rem;
  color: red;
  text-align: left;
  margin: 3px 7px 0px 7px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
}
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 7px 50px 7px 50px;
  border-radius: 200px;
  background-color: #5d5c95;
  margin: 10px 0px 10px 0px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.8rem;
  height: fit-content;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.nextButton:disabled {
  background-color: rgb(234, 234, 234);
  color: rgb(159, 159, 159);
  cursor: auto;
}
.healthDetailsContainer {
  margin-top: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
}
.questionsStyleBox {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  margin: 10px 0px 10px 0px;
}
.doneButton {
  border: none;
  padding: 10px 25px 10px 25px;
  border-radius: 200px;
  background-color: #27dba2;
  color: white;
  cursor: pointer;
  width: fit-content;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
}

.modalContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.closeButtonContainer {
  justify-content: right;
  align-items: right;
  text-align: right;
  /* margin-bottom: px; */
}
.otpField {
  margin: 0px 10px 10px 10px;
  background-color: #ededed;
  border: none;
  border-radius: 5px;
  height: 30px;
}
.questionLabel {
  font-size: small;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: rgb(81, 81, 81);
  font-weight: 600;
}
.questionField {
  margin-bottom: 20px;
}
.fieldStylesQuestions {
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 90%;
  margin-top: 5px;
}
.dividerStyles {
  margin: 10px 0px 12px 0px !important;
}
.verifyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 5px 25px 5px 25px;
  border-radius: 200px;
  background-color: #46c6a0;
  box-shadow: 0px 1px 4px 0px #4b4b4b;
  color: white;
  cursor: pointer;
  width: fit-content;
  font-size: small;
  margin-top: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
.verifyButton:disabled {
  background-color: rgb(234, 234, 234);
  color: rgb(159, 159, 159);
  cursor: auto;
}
.verifyBtnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.verifyOTPButton {
  border: none;
  padding: 10px 25px 10px 25px;
  border-radius: 200px;
  background-color: #5d5c95;
  color: white;
  cursor: pointer;
  width: fit-content;
  margin: 0px 10px 10px 10px;
}
.verifyOTPButton:disabled {
  background-color: rgb(234, 234, 234);
  color: rgb(159, 159, 159);
  cursor: auto;
}
.verifiedLabel {
  color: #25c08f;
  display: flex;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-size: small;
  /* background-color: #f1f1f1; */
  border-radius: 20px;
  padding: 3px 15px 2px 15px;
  width: fit-content;
  background-color: rgb(240, 240, 240);
  margin: 5px;
  border: 2px solid #25c08f;
}
.modalContent2 {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.tickIcon {
  height: 50px;
  width: 50px;
  color: rgb(65, 194, 65);
}
.formLabel {
  display: flex;
  justify-content: left;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4b4b4b;
  margin: 3px;
}
/* .otpImage {
    flex-direction: column;
    position: relative;
    height: 120px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../public/optImage.avif");
    border-radius: 10px 0 0 10px;
    margin: 10px 30px 30px;
  }
  .otpImageCover img {
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../../public/optImage.avif");
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  } */
.optImageContainer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.otpImage {
  max-width: 100%;
  max-height: 100%;
  display: block; /* Ensures that it behaves like a block-level element */
}

.otpVerificationLabel {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.labelWording {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: medium;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}
.labelNumber {
  font-weight: 500;
  font-size: medium;
}

.mandatoryMessage {
  font-size: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: rgb(137, 137, 137);
  font-weight: 600;
}
.submitContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.fieldStylesTimezone {
  display: flex;
  border: none;
  padding: 15px;
  border-radius: 5px;
  justify-content: left;
  width: 100%;
  /* height: 45px; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
}

.fieldStylesMultiDropDown {
  border: none;
  /* padding: 10px; */
  border-radius: 5px;
  width: 90%;
  margin-top: 5px;
}
.fieldStylesPhone {
  display: flex;
  border: none;
  padding: 7px 10px 7px 0px;
  border-radius: 5px;
  align-items: center;
  justify-content: left;
  width: 100% !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;

  /* width: 100%; */
  /* height: 45px; */
  background-color: white;
}

@media screen and (max-width: 2900px) {
  .fieldStylesTimezone {
    width: 93%;
  }
  .fieldStylesPhone {
    width: 92% !important;
  }
}
@media screen and (max-width: 1900px) {
  .fieldStylesTimezone {
    width: 95%;
  }
  .fieldStylesPhone {
    width: 94% !important;
  }
}
@media screen and (max-width: 1280px) {
  .fieldStylesTimezone {
    width: 97%;
  }
  .fieldStylesPhone {
    width: 94% !important;
  }
}
@media screen and (max-width: 1000px) {
  /* .fieldStylesTimezone {
    width: 97%;
  } */
  .fieldStylesPhone {
    width: 96% !important;
  }
}
@media screen and (max-width: 850px) {
  .fieldStylesTimezone {
    width: 100%;
  }
  .fieldStylesPhone {
    width: 97% !important;
  }
}
@media screen and (max-width: 680px) {
  .fieldStylesTimezone {
    width: 96%;
  }
  .fieldStylesPhone {
    width: 94% !important;
  }
}
@media screen and (max-width: 515px) {
  .fieldStylesTimezone {
    width: 98%;
  }
  .fieldStylesPhone {
    width: 95% !important;
  }
}
@media screen and (max-width: 385px) {
  .fieldStylesTimezone {
    width: 99%;
  }
  .fieldStylesPhone {
    width: 96% !important;
  }
}
/* custom-styles.css */

.form-container {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.your-form {
  display: flex;
  flex-direction: column;
}

.phoneInput {
  width: 100%;
}

.phone-input {
  width: 100% !important;
}

.circularImageContainer {
  width: 55px; /* Set the width and height according to your design */
  height: 55px;
  border-radius: 50%; /* Creates a circular shape */
  overflow: hidden; /* Clips the image to the circular container */
  background-color: rgb(255, 255, 255);
  padding: 5px;
  /* Adjust the margin as needed */
}

.circularImage {
  width: 100%; /* Ensures the image takes the full width of the circular container */
  height: 100%; /* Maintains the aspect ratio of the image */
  display: block; /* Removes extra spacing below the image */
}
.color {
  color: rgb(182, 54, 54);
  color: #4a459f;
  font-family: sans-serif;
}
