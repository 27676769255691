.loginMain {
  background: linear-gradient(90deg, #5d5c95, #ffff);
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginCard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 30px 20px 20px;
  width: 300px;
}
.loginHead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px;
}
.Head1 {
  font-size: medium;
}
.Head2 {
  font-size: 30px;
  font-weight: 700;
  color: #5d5c95;
}

.signInButton {
  background-color: #5d5c95;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
  border: none;
  display: flex;
  margin: 10px 40px 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.signInButton:hover {
  background-color: #fff;
  border: 2px solid #5d5c95;
  color: #5d5c95;
}
.imgCard {
  flex-direction: column;
  position: relative;
  height: 40%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/glucose.webp");
  border-radius: 10px 0 0 10px;
  margin: 10px 30px 30px;
}
.imageCover img {
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/glucose.webp");
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.click {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: smaller;
  margin-top: 20px;
}
