.firstContainer {
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
}
.label {
  font-size: small;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: rgb(81, 81, 81);
  font-weight: 600;
}
.medicineNameInput {
  border: none;
  padding: 5px;
  border-radius: 3px;
  margin-left: 5px;
  /* width: 150px; */
}
.nameContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 10px 5px 0px;
}

.timingsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 10px 5px 0px;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px 0px 5px;
}
.dropdownContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 10px 5px 0px;
}
.dropDownOption {
  border: none;
  font-size: small;
  color: rgb(99, 98, 98);
  font-weight: 500;
  margin: 0px 5px 0px 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue";
}
.fieldErrorMessage {
  font-size: small;
  color: red;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue";
}
.addButton {
  border: none;
  background-color: #5d5c95;
  border-radius: 3px;
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue";
  padding: 2px 8px 2px 8px;
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
}
.addButton:disabled {
  background-color: #cacaca;
  color: rgb(150, 150, 150);
}
.listViewContainer {
  border: none;
  padding: 8px 10px 8px 10px;
  border-radius: 5px;
  width: 90%;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  text-align: left;
  /* justify-content: left; */
  flex-wrap: wrap;
  background-color: white;
}
.listCard {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue";
  font-size: 0.8rem;
  font-weight: 400;
  margin: 1px 5px 1px 5px;
  background-color: #c8c8c8;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeButton {
  color: rgb(86, 85, 85);
  height: 16px !important;
  width: 16px !important;
  margin-left: 5px;
  cursor: pointer;
}
