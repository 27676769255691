.containerBody {
  /* background: linear-gradient(90deg, #06d897, #ffff); */
  background: linear-gradient(90deg, #5d5c95, #ffff);
  width: 100%;
  min-height: 100vh;
  max-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}
.popUpContainer {
  background-color: white;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
}
.popFirstHalf {
  height: 50%;
  width: 100%;
  padding-top: 15px;
  margin-bottom: -10px;
  background-color: #5d5c95;
  color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.popSecondHalf {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* padding-top: 15px; */
  /* padding-bottom: -10px; */
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.successLabel {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.successMessage {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(87, 87, 87);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.successMessage2 {
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(125, 125, 125);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
/* styles.module.css */

.successCheckmark {
  width: 80px;
  height: fit-content;
  margin: 0 auto;
  margin-top: 10px;
}

.successCheckmark .checkIcon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #9de0a0;
}

.successCheckmark .checkIcon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

.successCheckmark .checkIcon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

.successCheckmark .iconLine {
  height: 5px;
  background-color: #9de0a0;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.successCheckmark .iconLine.lineTip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

.successCheckmark .iconLine.lineLong {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

.successCheckmark .iconCircle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid #9de0a0;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.zoomContainer {
  text-align: center;
}

.zoomText {
  font-size: 0.9rem;
  animation: zoomIn 0.2s forwards;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
  font-size: 0.8rem;
  font-family: sans-serif;
  background-color: #049f38;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 5px 5px;
  animation: slide-up 0.5s ease-in-out forwards;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.whatsAppClick {
  border: none;
  border-radius: 5px;
  padding: 8px 15px 8px 15px;
  background-color: #00a884;
  color: white;
  box-shadow: 0px 2px 5px 0px rgba(67, 67, 67, 0.5);
  cursor: pointer;
}
